import { toRefs, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import store from 'store'

const profile = store.get('profile')

export default function useUserInfo() {
  const store = useStore()
  const settings = computed(() => store.getters.settings)

  const vendor_id = computed(() => profile.vendor_id)
  const regions = computed(() => profile.regions)
  const areas = computed(() => profile.areas)

  const isDistributor = computed(() => profile.roles.some(r => r.group_role === 'Distributor'))
  const isHelpdesk = computed(() => profile.roles.some(r => r.role === 'Helpdesk'))
  const isAm = computed(() => profile.roles.some(r => r.role === 'TSO'))
  const isSm = computed(() => profile.roles.some(r => r.role === 'ASM'))
  const isSsm = computed(() => profile.roles.some(r => r.role === 'SSM'))
  const isGm = computed(() => profile.roles.some(r => r.role === 'GM'))
  const isAdminBk = computed(()=>profile.roles.some(r => r.role === 'Administrator BK'))
  const isDistributor_khusus = computed(()=>profile.roles.some(r => r.role === 'Distributor Khusus'))
  const isRtmNonSemen = computed(()=>profile.roles.some(r => r.role === 'RTM Non Semen'))

  return {
    settings,
    profile,
    vendor_id,
    regions,
    areas,
    isDistributor,
    isHelpdesk,
    isAm,
    isSm,
    isSsm,
    isGm,
    isAdminBk,
    isDistributor_khusus,
    isRtmNonSemen,
  }
}


// import { toRefs, computed, reactive } from 'vue'
// import store from '@/store'

// export default function useUserInfo() {
//   const state = computed(() => store.state.user)
//   const isDistributor = computed(() => state.value.roles.some(r => r.group_role === 'Distributor'))
//   const isHelpdesk = computed(() => state.value.roles.some(r => r.role === 'Helpdesk'))
//   const isAm = computed(() => state.value.roles.some(r => r.role === 'TSO'))
//   const isSm = computed(() => state.value.roles.some(r => r.role === 'ASM'))
//   const isSsm = computed(() => state.value.roles.some(r => r.role === 'SSM'))
//   const isGm = computed(() => state.value.roles.some(r => r.role === 'GM'))
//   const isAdminBk = computed(()=>state.value.roles.some(r => r.role === 'Administrator BK'))
//   const isDistributor_khusus = computed(()=>state.value.roles.some(r => r.role === 'Distributor Khusus'))

//   return {
//     ...toRefs(state.value),
//     isDistributor,
//     isHelpdesk,
//     isAm,
//     isSm,
//     isSsm,
//     isGm,
//     isAdminBk,
//     isDistributor_khusus,
//   }
// }
