/* eslint-disable */

<template>
  <a-select
    mode="multiple"
    v-model:value="valueState"
    style="width: 300px"
    placeholder="Pilih Kabupaten/Kota"
    allow-clear
    :not-found-content="loading ? undefined : 'Tidak ditemukan'"
    option-filter-prop="label"
    option-label-prop="label"
    @search="onSearch"
    @blur="() => (findText = null)"
    @select="dropdownVisibleChange"
    @dropdownVisibleChange="dropdownVisibleChange"
    :show-arrow="true"
  >
    <a-select-option v-for="d in data" :key="d.id" :label="d.name">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="highlight(d.name)"></span>
      <!--eslint-enable-->
    </a-select-option>
    <template v-if="loading" #notFoundContent>
        <ASpin size="small" />
    </template>
  </a-select>
</template>

<script>
import { onMounted, ref, toRefs, watch } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { debounce } from 'lodash'

export default {
  props: {
    value: {
      default: () => [],
      type: Array,
    },
    area: {
      default: () => [],
      type: Array,
    },
    region: {
      default: () => [],
      type: Array,
    },
    provinsi: {
      default: () => [],
      type: Array,
    },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const data = ref([])
    const findText = ref(null)
    const area = toRefs(props).area
    const region = toRefs(props).region
    const provinsi = toRefs(props).provinsi
    const loading = ref(false)

    const fetchData = debounce((area, region, provinsi, q = null) => {
      if (area === undefined || area.length <= 0) area = null
      if (region === undefined || region.length <= 0) region = null
      if (provinsi === undefined || provinsi.length <= 0) provinsi = null

      loading.value = true

      apiClient
        .get('/api/filter/wilayah-by-role/2', {
          params: { area, region, provinsi, q },
        })
        .then(response => {
          const sorted = response.data
          data.value = sorted.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          loading.value = false
        })
    }, 600)

    onMounted(() => {
      fetchData(area.value)
    })

    watch(area, (after, before) => {
      emit('update:value', [])
      fetchData(after, region.value, provinsi.value)
    })

    watch(region, (after, before) => {
      fetchData(area.value, after, region.value)
    })

    watch(provinsi, (after, before) => {
      fetchData(area.value, region.value, after)
    })

    const onSearch = debounce(value => {
      findText.value = value
      fetchData(area.value, region.value, provinsi.value, value)
    }, 300)

    const dropdownVisibleChange = value => {
      console.log({ dropdownVisibleChange: '', value })
      if (findText.value !== null) {
        fetchData(area.value, region.value, provinsi.value)
        findText.value = null
      }
    }

    const highlight = value => {
      return value.replace(new RegExp(findText.value, 'gi'), match => {
        return `<span style="background-color: yellow;">${match}</span>`
      })
    }
    return {
      data,
      valueState: useVModel(props, 'value'),
      findText,
      highlight,
      onSearch,
      dropdownVisibleChange,
      loading,
    }
  },
}
</script>

<style></style>
