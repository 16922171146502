<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"/>

    <!-- filter -->
    <ARow :gutter="[8, 8]">
      <ACol :xl="6" :md="8" :sm="12" :span="24" v-can:hide.distributor>
        <filter-regional
          style="width:100%;"
          v-model:value="state.params.region"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <filter-province
          style="width:100%;"
          v-model:value="state.params.provinsi"
          v-model:region="state.params.region"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24" v-can:hide.distributor>
        <filter-area
          style="width:100%;"
          v-model:value="state.params.area"
          v-model:provinsi="state.params.provinsi"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24" v-can:hide.distributor>
        <filter-kabupaten-kota
          style="width:100%;"
          v-model:value="state.params.kabupaten"
          v-model:region="state.params.region"
          v-model:provinsi="state.params.provinsi"
          v-model:area="state.params.area"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24" v-can:hide.distributor>
        <filter-distributor
          style="width:100%;"
          v-model:value="state.params.distributor"
          v-model:distributor="state.params.distributor"
          v-model:region="state.params.region"
          v-model:area="state.params.area"
          v-model:provinsi="state.params.provinsi"
          v-model:kabupaten="state.params.kabupaten"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <a-input-search
          v-model:value="state.params.q"
          placeholder="Cari ..."/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <a-button
          type="primary"
            title="cari"
            @click="fetchData"
            :loading="state.isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!state.isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </ACol>
    </ARow>

    <div class="row justify-content-end">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <ASpace>
          <ATooltip title="Download Excel">
            <DownloadExcel
              :url="state.endpoint.current"
              :params="queryParams()"
              @errors="errorMessage"/>
          </ATooltip>
        </ASpace>
      </div>
    </div>

    <!-- table lists -->
    <div class="table-responsive mt-4">
      <md-table
        :columns="state.columns"
        :data-source="state.data"
        size="small"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: state.meta.total,
          pageSize: state.meta.per_page,
          current: state.meta.page,
        }"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        @change="handleTableChange"
        :loading="state.isFetching">
        <template #no="{ index }">
          <span>
            {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
          </span>
        </template>
        <template #region="{ text }">
          <span>{{ text.region_name }}</span>
        </template>
        <template #customer_code="{ text }">
          <span>{{ text.id_toko }}</span>
        </template>
        <template #customer_name="{ text }">
          <span>{{ text.nama_toko }}</span>
        </template>
        <template #customer_address="{ text }">
          <span>{{ text.address }}</span>
        </template>
        <template #area="{ text }">
          <span>{{ text.area_name }}</span>
        </template>
        <template #kota="{ text }">
          <span>{{ text.kabupaten }}</span>
        </template>
        <template #provinsi="{ text }">
          <span>{{ text.provinsi }}</span>
        </template>
        <template #user_ssm="{ text }">
          <span>{{ text.ssm != null ? text.ssm : '-' }}</span>
        </template>
        <template #user_sm="{ text }">
          <span>{{ text.sm != null ? text.sm : '-' }}</span>
        </template>
        <template #user_am="{ text }">
          <span>{{ text.am != null ? text.am : '-' }}</span>
        </template>
        <template #name_dist="{ text }">
          <span>{{ text.dist_vol_list }}</span>
        </template>
        <template #avarage="{ text }">
          <span>{{ text.avg_volume != null ? text.avg_volume : '-' }}</span>
        </template>
        <template #action="{text}">
          <div class="d-flex align-items-center">
            <a-tooltip title="Download Detail Average Volume Toko (3 Bulan)">
              <a-button
                @click="fetchXlsx3Month(text.id_toko)"
                class="button mx-1  btn-sm"
                size="small"
                :disabled="text.avg_volume === null || text.avg_volume === 0 ? true : false"
              >
                <i
                  class="fa fa-file-excel-o"
                  aria-hidden="true"
                  v-if="text.progress_download === false"
                ></i>
                <span v-else>Downloading ...</span>
              </a-button>
            </a-tooltip>
          </div>
        </template>
      </md-table>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, reactive } from 'vue'
import apiClient from '@/services/axios'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import qs from 'qs'
import moment from 'moment'
import useUserInfo from '@/composables/useUserInfo'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/globalmixin'

import { useRoute } from 'vue-router'

export default defineComponent({
  components: {
    FilterRegional,
    FilterProvince,
    FilterArea,
    FilterKabupatenKota,
    FilterDistributor,
    DownloadExcel,
  },
  setup() {
    const route = useRoute()
    const { isDistributor, isHelpdesk, isAm, vendor_id, regions, areas } = useUserInfo()
    const errorMessage = ref(null)
    const state = reactive({
      columns: [
        {
          title: 'NO',
          slots: { customRender: 'no' },
        },
        {
          title: 'Region',
          slots: { customRender: 'region' },
        },
        {
          title: 'ID Toko',
          slots: { customRender: 'customer_code' },
        },
        {
          title: 'Nama Toko',
          slots: { customRender: 'customer_name' },
        },
        {
          title: 'Alamat',
          slots: { customRender: 'customer_address' },
        },
        {
          title: 'Area',
          slots: { customRender: 'area' },
        },
        {
          title: 'Kota',
          slots: { customRender: 'kota' },
        },
        {
          title: 'Provinsi',
          slots: { customRender: 'provinsi' },
        },
        {
          title: 'SSM',
          slots: { customRender: 'user_ssm' },
        },
        {
          title: 'ASM',
          slots: { customRender: 'user_sm' },
        },
        {
          title: 'TSO',
          slots: { customRender: 'user_am' },
        },
        {
          title: 'Distributor Non Mapping',
          slots: { customRender: 'name_dist' },
        },
        {
          title: 'Average Volume Toko (3 Bulan)',
          slots: { customRender: 'avarage' },
        },
        {
          title: 'Action',
          slots: { customRender: 'action' },
        },
      ],
      columnsMonth: [
        {
          title: 'NO',
          slots: { customRender: 'no' },
        },
        {
          title: 'Kode Customer',
          dataIndex: 'customer_code',
        },
        {
          title: 'Nama Customer',
          dataIndex: 'customer_name',
        },
        {
          title: 'Alamat Customer',
          dataIndex: 'customer_address',
        },
        {
          title: 'Regional Customer',
          dataIndex: 'customer_regional',
        },
        {
          title: 'Kabupaten Customer',
          dataIndex: 'customer_kabupaten',
        },
        {
          title: 'Provinsi Customer',
          dataIndex: 'customer_propinsi',
        },
        {
          title: 'Area Customer',
          dataIndex: 'customer_area',
        },
        {
          title: 'Tipe Cluster',
          dataIndex: 'cluster_name',
        },
        {
          title: 'Brand',
          dataIndex: 'brand_name',
        },
        {
          title: 'Kode Produk',
          dataIndex: 'product_code',
        },
        {
          title: 'Nama Produk',
          dataIndex: 'product_name',
        },
        {
          title: 'UOM 1',
          dataIndex: 'uom1',
        },
        {
          title: 'UOM 2',
          dataIndex: 'uom2',
        },
        {
          title: 'Harga',
          dataIndex: 'price',
        },
        {
          title: 'Kode Transaksi',
          dataIndex: 'transaksi_code',
        },
        {
          title: 'Kode Gudang',
          dataIndex: 'warehouse_code',
        },
        {
          title: 'Nama Gudang',
          dataIndex: 'warehouse_name',
        },
        {
          title: 'Regional',
          dataIndex: 'regional_name',
        },
        {
          title: 'Kabupaten Gudang',
          dataIndex: 'warehouse_kabupaten',
        },
        {
          title: 'Provinsi Gudang',
          dataIndex: 'warehouse_propinsi',
        },
        {
          title: 'Area Gudang',
          dataIndex: 'warehouse_area',
        },
        {
          title: 'Kode Distributor',
          dataIndex: 'distributor_code',
        },
        {
          title: 'Nama Distributor',
          dataIndex: 'distributor_name',
        },
        {
          title: 'SSM',
          dataIndex: 'user_ssm',
        },
        {
          title: 'ASM',
          dataIndex: 'user_sm',
        },
        {
          title: 'TSO',
          dataIndex: 'user_am',
        },
        {
          title: 'ERP',
          dataIndex: 'erp_name',
        },
      ],
      endpoint: {
        current: '/api/report-lelang/non-mapping',
        month: '/api/report-lelang/detail-nonmapping-volume3bulan/toko',
      },
      data: [],
      isFetching: false,
      meta: {
        per_page: 10,
        page: 1,
        total: 0,
      },
      params: extractQueryParams({
        q: '',
        region: [],
        area: [],
        provinsi: [],
        kabupaten: [],
        distributor: [],
        page: 1,
        'per-page': 10,
      }),
    })

    const handleTableChange = (page, filters, sorter) => {
      state.params.page = page.current
      state.params["per-page"] = page.pageSize

      fetchData()
    }

    const queryParams = () => {
      let params = state.params

      return updateUrlWithQuery(params)
    }

    const fetchData = async () => {
      state.isFetching = true
      await apiClient
        .get(state.endpoint.current, {
          params: queryParams(),
        })
        .then(({ data }) => {
          const { items, _meta } = data
          items.forEach((item, i) => {
            item.key = i
            item['progress_download'] = false
          })

          state.data = items
          state.meta.page = _meta.currentPage
          state.meta.per_page = _meta.perPage
          state.meta.total = _meta.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    const fetchXlsx3Month = code => {
      data.value.find(item => item.id_toko === code).progress_download = true
      apiClient
        .get(state.endpoint.month, {
          params: {
            ...queryParams(),
            customer_code: code,
            _export: 'xls',
            _columns: state.columnsMonth
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined)
              .map(({ dataIndex: key, title }) => ({ key, title })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `Detail ${(route.meta.title_db ? route.meta.title_db : route.meta.title)
              .toLowerCase()
              .replaceAll(' ', '-')}_${moment().format('DDMMYY_HHmm')}.xlsx`,
          )
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          data.value.find(item => item.id_toko === code).progress_download = false
        })
    }
    
    onMounted(() => {
      // validation roles default params
      if (isDistributor.value) {
        state.params.distributor.push(vendor_id.value)
      }
      if (isHelpdesk.value) {
        regions.value.forEach(item => {
          state.params.region.push(item.id)
        })
      }
      if (isAm.value) {
        areas.value.forEach(item => {
          state.params.area.push(item.id)
        })
      }
      
      fetchData()
    })

    return {
      state,
      errorMessage,
      queryParams,
      fetchData,
      handleTableChange,
      fetchXlsx3Month,
    }
  },
})
</script>
